import { ToastServiceMethods } from "primevue/toastservice";

// type definition for displayResponseErrors options
export interface DisplayResponseErrorsOptions {
  processMessage?: boolean;
  severity?: string;
  duration?: number;
  throwErr?: boolean;
}
export const displayResponseErrors = (errors: Array<string>|any, toast: ToastServiceMethods, options?: DisplayResponseErrorsOptions) => {
  if(!errors) return;
  let processMessage = options?.processMessage ?? false;
  let duration = options?.duration ?? 5000;
  let throwErr = options?.throwErr ?? false;
  let severity:any = options?.severity ?? "error";
  try {
    // if(typeof errors === "object" && !Array.isArray(errors)) {
    //   errors = Object.values(errors);
    //   /* console.log({
    //     arrErrs: errors
    //   }); */
    // }
    const errorsArray: Array<string> = (typeof errors === "string") ? [errors] : errors;

    errorsArray?.forEach((error: string) => {
      error = processMessage ? error.replace(/^[\["']+|[\]"']+$/g, '') : error;
      toast.add({ severity: severity, detail: error, life: duration });
    });
  } catch (error) {
    if(throwErr) {
      throw error;
    } else if(process.env.NODE_ENV === "development") {
      console.log(error);
    }
  }
}