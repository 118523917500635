<template>
  <div class="bg-white p-4 text-center">
    <div class="shape">
      <div class="shape__container">
        <i class="pi pi-check shape__text fw-bold" />
        <img class="shape__image" :src="`${staticUrl}/static/icons/hex-shape.svg`" alt="hex-shape" />
      </div>
    </div>
    <div class="mb-5 pb-2 fs-4 color-primary">
       {{thankYouMessage}}
      <span class="d-block pt-2">
        مرضى آخرين ينتظرون عطاؤك
      </span>
    </div>
    <!-- Division Line -->
    <div class="card-bg">
      <RecommenationCard />
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";
import {defineProps, computed} from 'vue';
import RecommenationCard from '@/components/layout/RecommendationCard.vue';

const staticUrl = inject('staticUrl');

const props = defineProps({
 donation_type:{
    type: String,
 }
});

const thankYouMessage = computed(() => {
  switch (props.donation_type) {
    case 'case':
      return 'شكراً لعطائك لقد اكتملت الحالة'
    case 'campaign':
      return 'شكراً لعطائك لقد اكتملت الحملة'
    case 'waqf':
      return 'شكراً لعطائك لقد اكتمل الوقف';
    default:
      return 'شكراً لعطائك لقد اكتملت الحالة';
  }
});

</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.card-bg {
  padding-top: 70px;
  padding-bottom: 50px;
  background-color: $color-primary-background;
  border-top: 1px solid $color-primary-border;
}

.recommendation-header {
  border: 1px solid #E2E2E2;
  border-radius: 10px 10px 0 0;
  display: inline-block;
  padding: 10px 30px;
  border-bottom: none;
  color: #555555;
}

.shape {
  width: 280px;
  height: 260px;
  margin: auto;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }

  &__text {
    color: $color-footer-bcg;
    z-index: 1;
    font-size: 100px;
  }
  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
  }
}
</style>