export default {
  state: {
    requestID: null,
    action: '',
   identityNumber: '',
  },
  mutations: {
    SET_REQUEST_ID(state: any, payload: any) {
        state.requestID = payload;
  },
    SET_IDENTITY_NUMBER(state: any, payload: any) {
        state.identityNumber = payload;
    },
    SET_ACTION(state: any, payload: any) {
      state.action = payload;
    }
  },
}
