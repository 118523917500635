import axios, { AxiosRequestConfig } from "axios";

const baseURL = process.env.VUE_APP_API_URL;

class WalletService {
  static buildApiUrlAndParams(
    url: string,
    params: Record<string, string | number | object | undefined | null> = {}
  ): { url: string } {
    const searchFilters = params.searchFilters;
    if (
      searchFilters &&
      typeof searchFilters === "object" &&
      !Array.isArray(searchFilters)
    ) {
      Object.assign(params, searchFilters);
      delete params.searchFilters;
    }
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (
        value !== undefined &&
        value !== null &&
        value.toString().trim() !== ""
      ) {
        queryParams.append(key, value.toString().trim());
      }
    });
    const queryString = queryParams.toString();
    return {
      url: queryString ? `${url}?${queryString}` : url,
    };
  }

  static getWalletsMainGrid(
    params: Record<string, string | number | undefined | null> = {}
  ) {
    const paramsCopy = { ...params };
    const url = `${baseURL}/wallet/portfolio-management/`;
    const { url: finalUrl } = this.buildApiUrlAndParams(url, paramsCopy);
    return axios.get(finalUrl);
  }

  static getWalletOperationsLog(
    params: Record<string, string | number | object | undefined | null> = {}
  ) {
    const walletId = params.walletId;
    const paramsCopy = { ...params };
    delete paramsCopy.walletId;
    let url = `${baseURL}/wallet/${walletId}/operations-log/`;
    const { url: finalUrl } = this.buildApiUrlAndParams(url, paramsCopy);

    return axios.get(finalUrl);
  }

  static getFinancialOperationsLog(
    params: Record<string, string | number | object | undefined | null> = {}
  ) {
    const walletId = params.walletId;
    const paramsCopy = { ...params };
    delete paramsCopy.walletId;
    const url = `${baseURL}/wallet/${walletId}/operations-log/`;
    const { url: finalUrl } = this.buildApiUrlAndParams(url, paramsCopy);

    return axios.get(finalUrl);
  }

  static getDonorWallets(
    params: Record<string, string | number | object | undefined | null> = {}
  ) {
    const paramsCopy = { ...params };
    const url = `${baseURL}/wallet/donor-wallets/`;
    const { url: finalUrl } = this.buildApiUrlAndParams(url, paramsCopy);

    return axios.get(finalUrl);
  }

  static getWalletStatisticsData(
    params: Record<string, string | number | object | undefined | null> = {}
  ) {
    const walletId = params.walletId;
    const paramsCopy = { ...params };
    delete paramsCopy.walletId;
    let url = `${baseURL}/wallet/${walletId}/stats/`;
    const { url: finalUrl } = this.buildApiUrlAndParams(url, paramsCopy);

    return axios.get(finalUrl);
  }

  static getWalletsOperationsLogsDropdown() {
    const url = `${baseURL}/wallet/operations-log/filters`;
    return axios.get(url);
  }

  static getDonorWalletTopUpTransactions({
    walletId,
    page,
    page_size,
    order_by,
    sort,
    export_file,
  }: any) {
    let url = `${baseURL}/wallet/donor-wallet/${walletId}/incoming-transactions/?page=${page}&page_size=${page_size}`;
    url += order_by ? `&order_by=${order_by}` : "";
    url += sort ? `&sort=${sort}` : "";
    url += export_file ? `&export=${export_file}` : "";

    return axios.get(url);
  }

  static getDonorWalletLogs(id: any) {
    const url = `${baseURL}/wallet/donor-wallet/${id}/logs`;

    return axios.get(url);
  }

  static getDonorWalletDetails(id: any) {
    const url = `${baseURL}/wallet/donor-wallet/${id}`;

    return axios.get(url);
  }

  static donateFromDonorWallet(id: any, payload: any) {
    const url = `${baseURL}/wallet/donor-wallet/${id}/`;

    return axios.post(url, payload);
  }

  static getZakatWallet() {
    const url = `${baseURL}/wallet/zakat-wallet/`;

    return axios.get(url);
  }

  static createWallet(requestBody: any = {}) {
    const url = `${baseURL}/wallet/create/`;

    return axios.post(url, requestBody);
  }

  static updateWallet(requestBody: any = {}, walletId: number | null = null) {
    const url = walletId
      ? `${baseURL}/wallet/update/${walletId}`
      : `${baseURL}/wallet/create/`;

    return axios.patch(url, requestBody);
  }

  static createDonorWallet(requestBody: any = {}) {
    const url = `${baseURL}/wallet/create-donor-wallet/`;

    return axios.post(url, requestBody);
  }

  static getDiseases() {
    const url = `${baseURL}/charity/cases/medical-data/dropdown`;

    return axios.get(url);
  }

  static transferFromWallet(requestBody: any = {}) {
    const url = `${baseURL}/wallet/transfer-money/`;

    return axios.post(url, requestBody);
  }

  static getCases(id = 0, quickDonation = false) {
    let url = `${baseURL}/wallet/get-to-wallets/?`;
    url += quickDonation ? `quick-donation=1` : `source=${id}`;

    return axios.get(url);
  }

  static getAllCasesDonationWalletId() {
    const url = `${baseURL}/wallet/all-cases-wallet/`;

    return axios.get(url);
  }

  static getWalletDetails(id: number) {
    const url = `${baseURL}/wallet/get-wallet/${id}`;

    return axios.get(url);
  }

  static updateWalletDetails(id: number, name: string) {
    const url = `${baseURL}/wallet/update/${id}`;

    return axios.patch(url, { name });
  }

  static updateDonorWalletDetails(wallet_id: number, wallet_name: string) {
    const url = `${baseURL}/wallet/create-donor-wallet/`;

    return axios.patch(url, { wallet_id, wallet_name });
  }

  static getMajorDonors(limit: number = 10, offset: number = 0) {
    const url = `${baseURL}/wallet/top-donors/?limit=${limit}&offset=${offset}`;

    return axios.get(url);
  }

  static deleteWallet(id: number) {
    const url = `${baseURL}/wallet/delete/${id}`;

    return axios.delete(url);
  }

  static postWallet(requestBody: any) {
    const url = `${baseURL}/wallet/wallet-create-test/`;

    return axios.post(url, requestBody);
  }
}

export default WalletService;

export enum WALLET_TYPES {
  Most_Urgent = "الأشد حاجة",
  Waqf = "وقف",
  Auto = "تلقائي",
  Custom = "مخصص",
  Internal = "داخلي",
}
