<template>
  <div>
    <Card class="card-board__item case-card-item case-card-item--campaign d-flex flex-column justify-content-between" data-cy="campaign-card">
      <template #header>
        <div class="position-relative">
          <h2 class="case-card-item__heading text-center">حملة</h2>
          <VLazyImage v-if="!campaign.image" class="condition-img"
            data-cy="condition-image"
            loading="lazy"
            :src="`${staticUrl}/static/images/test-image.png`"
            alt="صورة الحالة"
            width="300"
            height="150"
          />
          <VLazyImage v-else class="condition-img"
            :src="campaign.image"
            alt="صورة الحالة"
            width="300"
            height="150"
          />
<!--          <img class="charity-image position-absolute" :src="case_.urls?.charity_image" alt="صورة الجمعية">-->
          <div v-if="campaign.remaining_period" data-cy="remaining-period"
               class="remaining-period px-3 py-1 position-absolute d-flex align-items-center justify-content-center opacity-50 bg-black rounded-5 text-white">
            <div class="pi pi-clock px-2"></div>
            <div class="pb-1">المدة المتبقية {{ campaign.remaining_period }} أيام</div>
          </div>
        </div>
        <div class="remaining_amount_card">
          <div class="percentage-card pointer">
            <span class="case-card-item__remaining-amount">المتبقي <span> {{ Math.ceil(campaign.target - campaign.balance).toLocaleString("en-US") }} </span> ريال</span>
            <div data-cy="progress-bar" class="progress mt-1">
              <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                   :style="{width: calculatePercentage <= 100 ? calculatePercentage + '%' : '100%'}"
                   aria-valuemax="100">
                <span v-if="calculatePercentage >= 20" class="progress-bar__percentage">{{calculatePercentage <= 100 ? calculatePercentage + '%' : '100%'}}</span>
              </div>
              <div class="hint" style="right: 9%">
                {{ Math.trunc((campaign.balance / campaign.target) * 100) }}%
              </div>
            </div>
          </div>
          <div class="remaining_amount_card__details">
          <span>
            <span data-cy="case-id" class="color-complementary">#</span>
            {{ campaign.id }}
          </span>
          </div>
        </div>
      </template>
      <template #content>
        <div class="case-card-item__content row w-100 m-0 mt-3">
            <div class="fw-bold title text-center" :title="campaign.full_title" data-cy="case-title">
              <span>{{campaign.full_title}}</span>
            </div>
            <div class="case-card-item__goal my-4 d-flex flex-column" data-cy="case-description">
              <span class="color-primary">هدف الحملة</span>
              <span v-if="campaign.typ === 'Waqf_sehi'">وقف صحي</span>
              <span v-else-if="campaign.typ==='Case'">لحالة {{campaign.specific_case}} </span>
              <span v-else>{{campaign.specialty_name}}</span>
            </div>
          </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-between">
          <a v-if="canDonate" class="case-card-item__donate" :href="`/donate-campaign/${campaign.id}`">
            <Button class="p-button-primary w-100"
                    label="تبرع الآن"
                    data-cy="donate-campaign"
                    :disabled="!canDonate"
                    v-if="!close" />
          </a>
          <span v-else-if="calculatePercentage >= 100" class="d-flex align-items-center justify-content-center w-100">
          مكتمل
        </span>
          <span v-else class="d-flex align-items-center justify-content-center w-100">
          تبرع الآن
        </span>
          <Button class="p-button-secondary button-share me-2" title="إبلاغ عن الحملة"
                  @click="$emit('reportCampaign')"
                  :disabled="campaign.is_reported || !canDonate"
                  data-cy="report-campaign"
                  icon="pi pi-exclamation-circle"/>
          <DonorCaseShareIcon class="me-2" :sharable-link="sharableLink" :authenticated="false" variant="legacy" aria-label="share campaign" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import JwtUtilService from "@/services/jwt.util.service";
import VLazyImage from "v-lazy-image";
import DonorCaseShareIcon from '@/components/campaigns/DonorCaseShareIcon'

export default {
  name: "campaign-card",
  inject: ['staticUrl'],
  components: { VLazyImage, DonorCaseShareIcon },
  emits: ["reportCampaign"],
  props: {
    campaign: {}
  },
  data() {
    return {
      sharableLink: `${process.env.VUE_APP_API_URL}/share/${this.$props.campaign?.id}?object_type=campaign`,
      canDonate: JwtUtilService.getClientID()?.includes("doner") || !this.$store.getters.isAuthenticated,
    };
  },
  mounted() {
    let remaining_period_in_days = Math.ceil((new Date(this.$props.campaign?.end_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    this.$props.campaign.remaining_period = remaining_period_in_days > 0 ? remaining_period_in_days : 0;
  },
  computed: {
    calculatePercentage() {
      return Math.trunc((this.campaign.balance / this.campaign.target) * 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/case-card";
</style>
