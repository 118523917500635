<template>
  <div>
    <div class="welcome-container row">
      <div class="col-12 title">بيانات عامة عن الجمعية:</div>
    </div>
    <form class="form-step1 w-100">
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="charity_name"
              type="text"
              :disabled="loading"
              v-model="payload.charity_name"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    (submitted && !payload.charity_name) ||
                    apierrors.charity_name,
                },
              ]"
            />
            <label for="charity_name">اسم المؤسسة الخيرية</label>
          </span>
        </div>
      </div>
      <Dropdown
        optionLabel="name"
        optionValue="id"
        :disabled="loading"
        v-model="payload.charity_type"
        :class="[
          'w-100',
          {
            'p-invalid':
              (submitted && !payload.charity_type) || apierrors.charity_type,
          },
        ]"
        :options="type_options"
        placeholder="نوع الجمعية"
      />
      <Dropdown
        v-if="
          payload.charity_type &&
          type_options &&
          type_options.length &&
          type_options.find((e) => e.id === payload.charity_type).is_specialist
        "
        optionLabel="name"
        optionValue="id"
        :disabled="loading"
        v-model="payload.charity_specialization"
        :class="[
          'w-100 mb-2 mt-4',
          {
            'p-invalid':
              (submitted && !payload.charity_specialization) ||
              apierrors.charity_specialization,
          },
        ]"
        :options="specialization_options"
        placeholder="التخصص الخيري"
      />
      <div class="row mb-2 mt-4">
        <div class="form-label-group mb-3 col-lg-6">
          <Dropdown
            :filter="true"
            emptyFilterMessage="لا يوجد نتائج"
            optionLabel="name"
            optionValue="id"
            :disabled="loading"
            v-model="payload.region"
            @change="regionChange"
            :class="[
              'w-100',
              {
                'p-invalid': (submitted && !payload.region) || apierrors.region,
              },
            ]"
            :options="region_options"
            placeholder="المنطقة"
          />
        </div>
        <div class="form-label-group mb-3 col-lg-6">
          <Dropdown
            :filter="true"
            emptyFilterMessage="لا يوجد نتائج"
            optionLabel="name"
            optionValue="id"
            :disabled="!payload.region || loading"
            v-model="payload.city"
            :class="[
              'w-100',
              {
                'p-invalid': (submitted && !payload.city) || apierrors.city,
              },
            ]"
            :options="city_options"
            placeholder="المدينة"
          />
        </div>
      </div>
      <div v-if="!payload.charity_logo">
        <span
          :class="[
            'attachment-container',
            {
              'in-valid':
                (submitted && !payload.charity_logo) || apierrors.charity_logo,
            },
          ]"
          @click.stop="$refs.charity_logo.$el.click()"
        >
          <img
            :src="`${staticUrl}/static/images/attachment.png`"
            alt="attachment"
          />
          <span class="label">إرفاق شعار الجمعية</span>
        </span>
        <InputText
          ref="charity_logo"
          class="d-none"
          type="file"
          accept="image/png, image/jpg"
          :disabled="loading"
          @change="(event) => charityLogoChange(event)"
        />
      </div>
      <div v-else>
        <label class="association-label">إرفاق شعار الجمعية</label>
        <div
          class="association-logo d-flex flex-wrap align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center upload-wrapper">
            <div class="logo w-100">
              <img :src="charityLogoUrl" alt="charity_logo" />
            </div>
            <div class="logo-name">
              <div class="text-truncate">
                {{ payload.charity_logo.name }}
              </div>
              <div>
                {{ getbytes(payload.charity_logo.size) }}
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn btn-lg btn-primary btn-block cancel-btn"
              @click="$emit('removeFile', 'charity_logo')"
            >
              إلغاء
            </button>
          </div>
        </div>
      </div>
      <div style="color: #3f3f3f; font-size: 13px" class="mb-3 mt-3">
        يفضل أن تكون خلفية الشعار شفافة. الأبعاد: px72X72. الملفات المقبولة: PNG
        أو JPG.
      </div>
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <BaseInputNumber
              :label="'رقم الجمعية (جوال أو هاتف)'"
              v-model="payload.charity_phone"
              :disabled="loading"
              :class="{
                'p-invalid':
                  apierrors.charity_phone ||
                  (submitted && !payload.charity_phone) ||
                  (payload.charity_phone && !verified_phone)
                    ? true
                    : false,
              }"
              id="charity_phone"
              :maxLength="10"
              :phoneNumber="true"
            />
          </span>
          <div
            v-if="payload.charity_phone && !verified_phone"
            class="invalid-email-label"
            style="color: #e24c4c"
          >
            الرجاء إدخال رقم هاتف محمول أو خط أرضي صالح
          </div>
        </div>
      </div>
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="charity_email"
              type="email"
              :disabled="loading || payload.is_charity_email_verified"
              v-model="payload.charity_email"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    apierrors.charity_email ||
                    (submitted && !payload.charity_email) ||
                    (payload.charity_email && !verified_email)
                      ? true
                      : false,
                },
              ]"
            />
            <label for="charity_email">بريد الجمعية الإلكتروني</label>
          </span>
          <div
            v-if="payload.charity_email && !verified_email"
            class="invalid-email-label"
            style="color: #e24c4c"
          >
            الرجاء إدخال عنوان بريد إلكتروني صالح
          </div>
        </div>
      </div>
      <div class="form-label-group mb-3">
        <div class="mt-4">
          <span class="p-float-label">
            <InputText
              id="charity_website"
              type="text"
              :disabled="loading"
              v-model="payload.charity_website"
              :class="[
                'w-100',
                {
                  'p-invalid':
                    apierrors.charity_website ||
                    (submitted && !payload.charity_website ? true : false),
                },
              ]"
            />
            <label for="charity_website">موقع الجمعية الإلكتروني</label>
          </span>
        </div>
      </div>
      <!-- Charity location -->
      <div class="form-label-group mb-3">
        <div class="mt-2">
          <span>اختر موقع الجمعية</span>
          <LocateMe @setLocation="setLocation" />
        </div>
      </div>
      <div class="border-container"></div>
      <div class="welcome-container row">
        <div class="col-12 title">بيانات ترخيص الجمعية:</div>
      </div>
      <div class="form-step1 w-100">
        <div class="form-label-group">
          <div class="mt-4">
            <span class="p-float-label">
              <InputText
                id="charity_license_number"
                type="number"
                v-model="payload.charity_license_number"
                :disabled="loading"
                :class="[
                  'w-100',
                  {
                    'p-invalid':
                      apierrors.charity_license_number ||
                      !!(
                        submitted &&
                        (!payload.charity_license_number ||
                          (payload.charity_license_number && !verifyLicense))
                      ),
                  },
                ]"
              />
              <label for="charity_license_number">رقم الترخيص</label>
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="form-label-group mb-3 col-lg-6">
            <div class="mt-4">
              <span class="p-float-label">
                <BaseCalendar
                  calendar-id="gregorian-license-expiry-date"
                  type="gregorian"
                  placeholder="تاريخ انتهاء الترخيص (ميلادي)"
                  v-model="payload.license_expiry_gregorian_date"
                  :class="[
                    'w-100',
                    {
                      'p-invalid':
                        submitted && !payload.license_expiry_gregorian_date,
                    },
                  ]"
                  :minDate="$parent.current_date"
                  :disabled="loading"
                  @dateChange="payload.license_expiry_hijri_date = $event"
                />
              </span>
            </div>
          </div>
          <div class="form-label-group mb-3 col-lg-6">
            <div class="mt-4">
              <span class="p-float-label">
                <BaseCalendar
                  calendar-id="hijri-license-expiry-date"
                  type="hijri"
                  placeholder="تاريخ انتهاء الترخيص (هجري)"
                  v-model="payload.license_expiry_hijri_date"
                  :class="[
                    'w-100',
                    {
                      'p-invalid':
                        (submitted && !payload.license_expiry_hijri_date) ||
                        apierrors.license_expiry_hijri_date,
                    },
                  ]"
                  :disabled="loading"
                  :minDate="$parent.current_date"
                  @dateChange="payload.license_expiry_gregorian_date = $event"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="mb-3 mt-3" v-if="!payload.license_attachment">
          <span
            :class="[
              'attachment-container',
              {
                'in-valid':
                  (submitted && !payload.license_attachment) ||
                  apierrors.license_attachment,
              },
            ]"
            @click.stop="$refs.license_attachment.$el.click()"
          >
            <img
              :src="`${staticUrl}/static/images/attachment.png`"
              alt="attachment"
            />
            <span class="label">إرفاق ملف الترخيص</span>
          </span>
          <InputText
            ref="license_attachment"
            type="file"
            accept="application/pdf"
            @change="(event) => licenseAttachmentChange(event)"
            class="d-none"
            :disabled="loading"
          />
        </div>
        <div v-else>
          <label class="association-label">إرفاق ملف الترخيص </label>
          <div
            class="association-logo d-flex flex-wrap align-items-center justify-content-between file"
          >
            <div class="d-flex align-items-center upload-wrapper">
              <div class="logo-name w-100">
                <div class="text-truncate">
                  {{ payload.license_attachment.name }}
                </div>
                <div>
                  {{ getbytes(payload.license_attachment.size) }}
                </div>
              </div>
            </div>
            <div>
              <button
                class="btn btn-lg btn-primary btn-block cancel-btn"
                @click="$emit('removeFile', 'license_attachment')"
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="border-container"></div>

    <div class="row">
      <div class="col-lg-6">
        <button
          id="close-btn"
          class="btn btn-lg btn-secondary btn-block close-btn"
          type="button"
          @click.stop="$emit('close')"
        >
          السابق
        </button>
      </div>
      <div class="col-lg-6">
        <button
          id="tracking-btn"
          class="btn btn-lg btn-primary btn-block tracking-btn"
          type="button"
          @click.stop="submit"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm mx-1"
          ></span>
          متابعة
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import EnumService from "../../../services/enum.service";
import { toBase64, isHttpUrl, addStepToCharity } from "@/helpers/helpers";
import BaseCalendar from "@/components/layout/BaseCalendar";
import FileAttachment from "@/components/layout/FileAttachment.vue";
import LocateMe from "@/components/layout/LocateMe.vue";

export default {
  name: "create-account-step2",
  inject: ["staticUrl"],
  props: ["payload", "verified_info", "apierrors"],
  emits: ["close", "next", "fileChange", "charityTypeChange", "removeFile"],
  components: { Dropdown, BaseCalendar, FileAttachment, LocateMe },
  data() {
    return {
      submitted: false,
      loading: false,
      error: null,
      type_options: [],
      specialization_options: [],
      region_options: [],
      city_options: [],
      fullName: `${this.verified_info.first_name} ${this.verified_info.father_name} ${this.verified_info.last_name}`,
      initialData: {},
      charityLogoUrl: null,
      licenseAttachmentUrl: null,
    };
  },
  created() {
    this.payload.license_expiry_gregorian_date =
      (this.payload.license_expiry_gregorian_date &&
        new Date(this.payload.license_expiry_gregorian_date)) ||
      "";
  },
  methods: {
    async charityLogoChange(event) {
      this.charityLogoUrl = await this.getImage(event.target.files[0]);
      const allowed_extention = ["png", "jpg"];
      const file_extention = event.target.files[0].name.split(".").pop();
      const file_size = event.target.files[0].size;
      if (allowed_extention.includes(file_extention) && file_size)
        this.$emit("fileChange", event, "charity_logo");
    },
    async getImage(file) {
      if (isHttpUrl(file)) {
        return file;
      } else if (typeof file === "string" && file.startsWith("data:")) {
        const response = await fetch(file);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } else {
        return URL.createObjectURL(file);
      }
    },
    getbytes(data) {
      const const_term = 1024;
      let KB, MB, GB, TB;

      KB = (data / const_term).toFixed(3);
      MB = (data / const_term ** 2).toFixed(3);
      GB = (data / const_term ** 3).toFixed(3);
      TB = (data / const_term ** 4).toFixed(3);

      if (TB > 0) {
        return TB + "TB";
      } else if (GB > 0) {
        return GB + "GB";
      } else if (MB > 0) {
        return MB + "MB";
      } else if (KB > 0) {
        return KB + "KB";
      }
    },
    submit() {
      this.submitted = true;
      if (
        this.payload.charity_name &&
        this.payload.charity_type &&
        this.payload.region &&
        this.payload.city &&
        this.payload.charity_logo &&
        this.payload.charity_phone &&
        this.verified_phone &&
        this.payload.charity_email &&
        this.verified_email &&
        this.payload.charity_website &&
        this.payload.longitude &&
        this.payload.latitude &&
        this.payload.chairman_name &&
        this.payload.chairman_mobile &&
        this.verified_mobile &&
        this.payload.charity_license_number &&
        this.verifyLicense &&
        this.payload.license_expiry_hijri_date &&
        this.payload.license_expiry_gregorian_date &&
        this.payload.license_attachment
      ) {
        // check if the data has been changed
        JSON.stringify(this.payload) !== this.initialData
          ? this.sendCharity()
          : this.$emit("next");
      }
    },
    enum_types() {
      this.loading = true;
      this.error = null;

      EnumService.types()
        .then(({ data }) => {
          if (data.result.length) this.type_options = data.result;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enum_specializations() {
      this.loading = true;
      this.error = null;

      EnumService.specializations()
        .then(({ data }) => {
          if (data.result.length) this.specialization_options = data.result;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enum_regions() {
      this.loading = true;
      this.error = null;

      EnumService.regions()
        .then(({ data }) => {
          if (data.result.length) this.region_options = data.result;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enum_cities(region_id) {
      this.loading = true;
      this.error = null;

      EnumService.cities(region_id)
        .then(({ data }) => {
          if (data.result.length) this.city_options = data.result;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    regionChange(data) {
      if (data) {
        this.enum_cities(data.value);
      }
    },
    setLocation(coords) {
      this.payload.longitude = coords.lng.toFixed(6);
      this.payload.latitude = coords.lat.toFixed(6);
    },
    licenseAttachmentChange(event) {
      const allowed_extention = ["pdf"];
      const file_extention = event.target.files[0].name.split(".").pop();
      if (allowed_extention.includes(file_extention))
        this.$emit("fileChange", event, "license_attachment");
    },
    async sendCharity() {
      this.loading = true;
      this.error = null;

      this.payload.charity_logo = {
        data: this.payload.charity_logo.data
          ? this.payload.charity_logo.data
          : await toBase64(this.payload.charity_logo),
        name: this.payload.charity_logo.name,
        size: this.payload.charity_logo.size,
      };
      this.payload.license_attachment = {
        data: this.payload.license_attachment.data
          ? this.payload.license_attachment.data
          : await toBase64(this.payload.license_attachment),
        name: this.payload.license_attachment.name,
        size: this.payload.license_attachment.size,
      };
      const payload = {
        charity_license_number: this.payload.charity_license_number,
        charity_name: this.payload.charity_name,
        charity_type: this.payload.charity_type,
        charity_specialization: this.payload.charity_specialization,
        charity_logo: this.payload.charity_logo,
        license_expiry_hijri_date: this.payload.license_expiry_hijri_date,
        license_expiry_gregorian_date:
          this.payload.license_expiry_gregorian_date,
        license_attachment: this.payload.license_attachment,
        charity_phone: this.payload.charity_phone,
        charity_email: this.payload.charity_email,
        charity_website: this.payload.charity_website,
        region: this.payload.region,
        city: this.payload.city,
        latitude: this.payload.latitude,
        longitude: this.payload.longitude,
        chairman_name: this.payload.chairman_name,
        chairman_mobile: this.payload.chairman_mobile,
      };
      addStepToCharity("step2", payload);
      this.$emit("next");
    },
  },
  async mounted() {
    this.enum_types();
    this.enum_regions();
    this.enum_specializations();
    if (this.payload.region) {
      this.enum_cities(this.payload.region);
    }
    if (this.$props.payload.the_charity_manager_is_the_chairman) {
      this.$props.payload.chairman_name = this.fullName;
    }
    // for charity logo and license file, change base64 to blob
    if (this.payload.charity_logo?.data) {
      if (
        typeof this.payload.charity_logo.data === "string" &&
        this.payload.charity_logo.data.startsWith("data:")
      ) {
        const response = await fetch(this.payload.charity_logo.data);
        const blob = await response.blob();
        this.charityLogoUrl = URL.createObjectURL(blob);
      }

      this.initialData = JSON.stringify(this.payload);
    }
  },
  computed: {
    verified_mobile() {
      const pattern =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
      const result = pattern.test(this.payload.chairman_mobile);

      return result;
    },
    verified_phone() {
      const pattern1 = /^(01)([0-9]{8})$/;
      const pattern2 =
        /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;

      const result =
        pattern1.test(this.payload.charity_phone) ||
        pattern2.test(this.payload.charity_phone);

      return result;
    },
    verified_email() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = pattern.test(this.payload.charity_email);

      return result;
    },
    verifyLicense() {
      return (
        this.payload.charity_license_number.length &&
        this.payload.charity_license_number.length >= 1 &&
        this.payload.charity_license_number.length <= 4
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-container {
  .title {
    font-size: 24px;
    font-weight: bold;
  }
}
.attachment-container {
  sendCharity .label {
    color: #3f3f3f;
    font-size: 18px;
    font-weight: 500;
    margin-right: 0.5rem;
  }
}
.border-container {
  border-top: 1px solid #dadada;
  padding-top: 2rem;
  margin-top: 2rem;
}

.attachment-container.in-valid {
  border: 1px solid red !important;
  background-color: transparent !important;
}
.tracking-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #12b2aa;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

.invalid-email-label {
  font-size: 14px;
  margin-top: 0.25rem;
}

.tracking-btn:hover {
  background-color: #0e8e88 !important;
  border: none;
}

.close-btn {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-radius: 25px;
  padding: 10px;
  background: #d9d9d9;
  color: #3f3f3f;
  border: none;
  font-size: 18px;
}

.association-logo {
  height: 80px;
  border-radius: 5px;
  border: 1px dashed #d9d9d9;
  padding: 10px 20px;
  .logo-name {
    color: #8c8c8c;
    font-size: 14px;
  }
  .upload-wrapper {
    width: 73%;
  }
  &:not(.file) {
    .upload-wrapper {
      width: 73%;
    }
    .logo-name {
      width: 72%;
    }
  }

  .logo {
    height: 50px;
    min-width: 70px;
    max-width: 70px;
    background-color: #d9d9d9;
    margin-left: 1rem;
  }
}

.association-label {
  font-size: 12px;
  left: 0rem;
  right: 0.75rem;
  position: relative;
}
.logo img {
  height: 100%;
  width: 100%;
}
.cancel-btn {
  border-radius: 25px;
  font-size: 14px;
  background-color: #d9d9d9;
  color: #3f3f3f;
  border: none;
}

.cancel-btn:hover {
  background-color: #b2b2b2;
  color: #3f3f3f;
}
</style>
