export enum STORE_ENUM {
  TOKEN_EXCHANGE = "token_exchange",
  AUTHENTICATE = "authenticate",
  SIGN_OUT = "signout",
  CASE_STATUSES = "case-statuses",
  MENU_ITEMS = "menu-items",
  USERNAME = "username",
  TOKEN_REFRESH = "refresh-token",
  CHARITY_REQUESTS = "charity-requests",
  TREATMENT_REQUESTS = "treatment-requests",
  WALLETS = "wallets",
  FINANCIAL_OPERATIONS = "financial-operations",
}
